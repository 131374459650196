/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        objectFitImages();

        // Toggle Mobile Menu
        $('.site-header .burger-menu').click(function(){
          $('body').toggleClass('nav-open');
        });

        // Toggle Buger Animation
        $('.burger-menu').click (function(){
          $(this).toggleClass('open');
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        sal({
          threshold: 0.2,
        }); // Initialise Sal (Scroll Animation Library)

        /* jshint ignore:start */
        // Scroll speed on elements
        $.fn.moveIt = function(){
          var $window = $(window);
          var instances = [];

          $(this).each(function(){
            instances.push(new moveItItem($(this)));
          });

          window.addEventListener('scroll', function(){
            var scrollTop = $window.scrollTop();
            instances.forEach(function(inst){
              inst.update(scrollTop);
            });
          }, {passive: true});
        };

        var moveItItem = function(el){
          this.el = $(el);
          this.speed = parseInt(this.el.attr('data-scroll-speed'));
        };

        moveItItem.prototype.update = function(scrollTop){
          this.el.css('transform', 'translateY(' + -Math.min((scrollTop / this.speed), 100) + 'px)');
          console.log(Math.min((scrollTop / this.speed), 100));
        };

        // Initialization
        $(function(){
          $('[data-scroll-speed]').moveIt();
        });
        /* jshint ignore:end */
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $('#client-slider').infiniteslide({
          'speed': 75, //speed this is px/min
          'direction': 'left', //choose  up/down/left/right
          'pauseonhover': true, //if true,stop onmouseover
          'responsive': false, //width/height recalculation on window resize. child element's width/height define %/vw/vh,this set true.
          'clone': 1 //if child elements are too few (elements can't "infinite"), set 2 or over.
        });
      }
    },
    // Projects page
    'projects': {
      init: function() {
        // JavaScript to be fired on the Projects page
      },
      finalize: function() {
        // JavaScript to be fired on the Projects page, after the init JS
        projectFilters();
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
