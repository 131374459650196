/* ========================================================================
 * Functionality for the project filters
 * ======================================================================== */

function projectFilters() {

  var $ = jQuery;

  $('.project-filter-btn').on('click', function(e) {
    e.preventDefault();
    $(this).toggleClass('on');
    $('.project-filters-area').toggleClass('on');
  });

  // Function for setting equal heights elements
  function setEqualHeightForShuffleItems(childItemSelector) {
    //selects all items
    var elements = $(childItemSelector);
    //puts the height of all items in an array
    var heights = elements.map(function (index, element) {
      return $(element).height();
    });
    //gets the highest value out of the array
    var maxHeight = Math.max.apply(this, heights);
    //sets height of all the items to be the same as the highest one
    elements.map(function (index, element) { $(element).height(maxHeight); });
  }


  function filter() {
    // Set equal height on cards
    setEqualHeightForShuffleItems('.card-shuffle-item');

    const featuredProject = $('div.featured-project');

    const featuredProjectCard = $('div.featured-project .card');

    var myShuffle = new Shuffle(document.querySelector('.card-shuffle'), {
      itemSelector: '.card-shuffle-item',
      sizer: '.sizer-element',
      buffer: 1,
      useTransforms: false,
    });

    var filterState = [];
    $('.filter-option').on('click', function (evt) {
      evt.preventDefault();

      var input = $(this);
      var value = input.attr('data-option');

      $(this).find('.filter-option__on').toggleClass('d-none');
      $(this).find('.filter-option__off').toggleClass('d-none');

      if (filterState.indexOf(value) > -1) {
        filterState.splice(filterState.indexOf(value), 1);
      } else {
        filterState.push(value);
      }

      console.log(value);
      console.log(filterState);

      if (filterState.length === 0) {
        featuredProject.removeClass("hide");
      } else {
        var hide = true;
        for (var state in filterState) {
          var hasClass = featuredProjectCard.hasClass(filterState[state]);
          if (hasClass) {
            featuredProject.removeClass("hide");
            hide = false;
          }
        }
        if (hide) {
          featuredProject.addClass("hide");
        }
      }

      // if (value === 'all' || filterState.length === 0) {
      //   // If View All filter clicked show featured project
      //   featuredProject.removeClass("hide");
      // } else if (hasClass) {
      //   // If one filter clicked that is assigned to featured project then show featured project
      //   featuredProject.removeClass("hide");
      // } else {
      //   featuredProject.addClass("hide");
      // }

      myShuffle.filter(filterState);


    });
  }

  $(window).load(function () {
    filter();
  });


}


